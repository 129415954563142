import InlineRouterLink from "@/components/help-center/custom-tags/InlineRouterLink.vue";

export const inlineRouterLink = {
  render: InlineRouterLink,
  attributes: {
    articleId: {
      type: String,
    },
    categoryId: {
      type: String,
    },
    sectionName: {
      type: String,
    },
    newTab: {
      type: Boolean,
    },
  },
};
