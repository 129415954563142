<template>
  <router-link v-if="newTab && articleId" :to="helpCenterPath" target="_blank"
    ><slot>{{
      category?.shortName ||
      category?.name ||
      article?.name ||
      "Unknown content"
    }}</slot></router-link
  >
  <a v-else href="" @click.prevent="navigate">
    <slot>{{
      category?.shortName ||
      category?.name ||
      article?.name ||
      "Unknown content"
    }}</slot></a
  >
</template>

<script setup>
import { useDocumentationStore } from "@/stores/documentation";
import { storeToRefs } from "pinia";
import _ from "lodash";

import { computed } from "vue";
import { useRoute, useRouter } from "vue-router";

const props = defineProps(["articleId", "categoryId", "sectionName", "newTab"]);

const documentationStore = useDocumentationStore();
const { helpViews, articles, categories } = storeToRefs(documentationStore);
const router = useRouter();
const route = useRoute();

const helpCenterPath = computed(() => {
  const sectionHash = props.helpSection
    ? { hash: `#${_.kebabCase(props.sectionName)}` }
    : {};
  return _.merge(
    {},
    {
      name: "HelpArticle",
      params: { articleId: props.articleId },
    },
    sectionHash,
  );
});

const article = computed(() => {
  if (props.articleId) {
    return _.find(articles.value, function (articleObj) {
      return _.lowerCase(articleObj.name) === _.lowerCase(props.articleId);
    });
  } else {
    return null;
  }
});
const category = computed(() => {
  if (props.categoryId) {
    return _.find(categories.value, { id: _.toNumber(props.categoryId) });
  } else {
    return null;
  }
});
function navigate() {
  if (article.value) {
    documentationStore.viewArticle(article.value, props.sectionName);
  } else if (category.value) {
    documentationStore.viewCategory(category.value);
  } else if (helpViews.value) {
    router.push({ name: "HelpCenter" });
  } else {
    router.push({
      name: route.name,
      query: {
        ...route.query,
        articleId: undefined,
        categoryId: undefined,
      },
    });
  }
}
</script>
